import { datadogRum } from "@datadog/browser-rum";
import packageInfo from "../../package.json";

datadogRum.init({
    applicationId: "f24b58f8-0180-438b-9399-9077b428916b",
    clientToken: "pub6772680188c7b1af05878856da4f89b2",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "tymeline-platform",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: packageInfo.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    startSessionReplayRecordingManually: true,
});

export const startRecording = () => {
    datadogRum.startSessionReplayRecording();
};

export const setUser = (user) => {
    datadogRum.setUser(user);
};
