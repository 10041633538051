// --------- This file should be made redundant soon --------//
// All the routes here will be moved to auth and unauth config files.

import React, { Fragment } from "react";
// import { Route } from "react-router-dom";
// import { useSelector } from "react-redux";
// import HiringForm from "funnel/components/required-components/hiring-component/HiringForm";
// import ScreenersPage from "../funnel/pages/ScreenersPage";
// import TestsPage from "../funnel/pages/TestsPage";
// import ScreenerQuestionsViewComponent from "../funnel/components/required-components/screener-component/ScreenerQuestionsViewComponent";
// import AddScreenerQuestions from "../funnel/components/required-components/screener-component/AddScreenerQuestions";
// import EditScreenerQuestion from "../funnel/components/required-components/screener-component/EditScreenerQuestion";
// import TestQuestionsViewComponent from "../funnel/components/required-components/test-component/TestQuestionsViewComponent";
// import AddTestQuestions from "../funnel/components/required-components/test-component/AddTestQuestions";
// import EditTestQuestion from "../funnel/components/required-components/test-component/EditTestQuestion";
// import HiringCandidateDetailsView from "../funnel/components/required-components/hiring-component/HiringCandidateDetailsView";
// // import IndividualUserDisplay from "../components/required-components/user-component/IndividualUserDisplay";
// import AuthForm from "layouts/authentication/sign-in";

const Routers = () => {
  // const state = useSelector(userSelector);

  // const DashboardPage = React.lazy(() => import("../pages/DashboardPage"));

  // const AuthForm = React.lazy(() =>
  //   import("./components/required-components/login-component/AuthForm")
  // );
  // const OnboardForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/OnboardForm"
  //   )
  // );

  // const MultipleCandidatesOnboardForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/MultipleCandidatesOnboardForm"
  //   )
  // );

  // const IndividualOnboardForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/IndividualOnboardForm"
  //   )
  // );
  // const EditPersonalDetails = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/EditPersonalDetails"
  //   )
  // );
  // const EditEducationDetails = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/EditEducationDetails"
  //   )
  // );
  // const EditWorkDetails = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/onboard-component/EditWorkDetails"
  //   )
  // );
  // const CompanyPage = React.lazy(() => import("../pages/CompanyPage"));
  // const EditCompanyDetailsForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/company-component/EditCompanyDetailsForm"
  //   )
  // );
  // const CompanyDetailsForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/company-component/CompanyDetailsForm"
  //   )
  // );
  // const RolesPage = React.lazy(() => import("../pages/RolesPage"));
  // const CreateRoleComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/role-component/CreateRoleComponent"
  //   )
  // );
  // const IndividualRoleDisplay = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/role-component/IndividualRoleDisplay"
  //   )
  // );
  // const EditRoleComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/role-component/EditRoleComponent"
  //   )
  // );
  // const UserForm = React.lazy(() =>
  //   import("../components/required-components/user-component/UserForm")
  // );
  // const EditUserForm = React.lazy(() =>
  //   import("../components/required-components/user-component/EditUserForm")
  // );
  // const UsersPage = React.lazy(() => import("../pages/UsersPage"));
  // const OrientationForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/orientation-component/OrientationForm"
  //   )
  // );
  // const EditOrientationComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/orientation-component/EditOrientationComponent"
  //   )
  // );
  // const EditQuestionsComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/orientation-component/edit-section/EditQuestionsComponent"
  //   )
  // );
  // const AddQuestionsComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/orientation-component/edit-section/AddQuestionsComponent"
  //   )
  // );
  // const OrientationViewPage = React.lazy(() =>
  //   import("../pages/rookie/OrientationViewPage")
  // );
  // const TrainingForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/training-component/TrainingForm"
  //   )
  // );
  // const EditTrainingForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/training-component/EditTrainingForm"
  //   )
  // );
  // const TrainingsPage = React.lazy(() =>
  //   import("../pages/rookie/TrainingsPage")
  // );
  // const TrainingsComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/training-component/TrainingsComponent"
  //   )
  // );
  // const IssueLetterForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/Issue-letters/IssueLetterForm"
  //   )
  // );
  // const EditIssueLetterForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/Issue-letters/EditIssueLetterForm"
  //   )
  // );
  // const IssueLettersPage = React.lazy(() =>
  //   import("../pages/rookie/IssueLettersPage")
  // );
  // const IssueLetterComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/Issue-letters/IssueLetterComponent"
  //   )
  // );
  // const CompanyPoliciesPage = React.lazy(() =>
  //   import("../pages/rookie/CompanyPoliciesPage")
  // );
  // const CompanyPolicyForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/company-policies/CompanyPolicyForm"
  //   )
  // );
  // const EditCompanyPolicyForm = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/company-policies/EditCompanyPolicyForm"
  //   )
  // );
  // const CompensationBenefitsPage = React.lazy(() =>
  //   import("../pages/rookie/CompensationBenefitsPage")
  // );
  // const CompensationBenifitsComponent = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/compensation-benifits/CompensationBenifitsComponent"
  //   )
  // );
  // const CreateCompensation = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/compensation-benifits/CreateCompensation"
  //   )
  // );
  // const EditCompensation = React.lazy(() =>
  //   import(
  //     "../components/required-components/rookie/compensation-benifits/EditCompensation"
  //   )
  // );
  // const WorkFlowPage = React.lazy(() => import("../pages/rookie/WorkFlowPage"));

  // const CreateWorkFlowPage = React.lazy(() =>
  //   import("../pages/rookie/CreateWorkFlowPage")
  // );
  // const EditWorkFlowPage = React.lazy(() =>
  //   import("../pages/rookie/EditWorkFlowPage")
  // );

  // const WorkFlowEditPage = React.lazy(() =>
  //   import("../pages/rookie/WorkFlowEditPage")
  // );
  // const ReportsPage = React.lazy(() => import("../pages/ReportsPage"));
  // const PaperbookPage = React.lazy(() =>
  //   import("../pages/rookie/PaperbookPage")
  // );
  // const OffboardingPage = React.lazy(() =>
  //   import("../pages/rookie/OffboardingPage")
  // );

  // const HiringWorkFlowPage = React.lazy(() =>
  //   import("../funnel/pages/HirinigWorkFlowPage")
  // );
  // const HiringWorkFlowEditPage = React.lazy(() =>
  //   import("../funnel/pages/HiringWorkFlowEditPage")
  // );

  // const EditHiringWorkFlowPage = React.lazy(() =>
  //   import("../funnel/pages/EditHiringWorkFlowPage")
  // );

  // const HiringFlowForm = React.lazy(() =>
  //   import(
  //     "../funnel/components/required-components/workflow-components/HiringFlowForm"
  //   )
  // );

  // const PublicLink = React.lazy(() =>
  //   import(
  //     "../funnel/components/required-components/workflow-components/PublicLink"
  //   )
  // );

  return (
    <Fragment>
      {
        // <Route exact path="/">
        //     <Redirect to="/login" />
        // </Route>
      }
      {/* <Route exact path="/login" component={SignIn} /> */}
      {/* <Route exact path="/login" component={AuthForm} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/password-verify" component={ForgotPasswordLink} />
      <Route exact path="/reset-password/:token" component={ResetPassword} /> */}
      {/* <Route
        exact
        path="/"
        component={state === null ? AuthForm : DashboardPage}
      /> */}
      {/* <Route
        exact
        path="/onboard"
        component={state === null ? AuthForm : OnboardForm}
      /> */}

      {/* <Route
        exact
        path="/multiple-onboards"
        component={state === null ? AuthForm : MultipleCandidatesOnboardForm}
      /> */}
      {/* <Route
        exact
        path="/individual-onboard"
        component={state === null ? AuthForm : IndividualOnboardForm}
      /> */}
      {/* <Route
        exact
        path="/individual-onboard/edit-personal-details"
        component={state === null ? AuthForm : EditPersonalDetails}
      /> */}
      {/* <Route
        exact
        path="/individual-onboard/edit-education-details"
        component={state === null ? AuthForm : EditEducationDetails}
      /> */}
      {/* <Route
        exact
        path="/individual-onboard/edit-work-details"
        component={state === null ? AuthForm : EditWorkDetails}
      /> */}
      {/* <Route
        exact
        path="/roles"
        component={state === null ? AuthForm : RolesPage}
      /> */}
      {/* <Route
        exact
        path="/view-role/:rid"
        component={state === null ? AuthForm : IndividualRoleDisplay}
      /> */}
      {/* <Route
        exact
        path="/create-role"
        component={state === null ? AuthForm : CreateRoleComponent}
      /> */}
      {/* <Route
        exact
        path="/edit-role/:id"
        component={state === null ? AuthForm : EditRoleComponent}
      /> */}
      {/* <Route
        exact
        path="/create-company"
        component={state === null ? AuthForm : CompanyDetailsForm}
      /> */}
      {/* <Route
        exact
        path="/company-details"
        component={state === null ? AuthForm : CompanyPage}
      /> */}
      {/* <Route
        exact
        path="/edit-company/:id"
        component={state === null ? AuthForm : EditCompanyDetailsForm}
      /> */}
      {/* <Route
        exact
        path="/orientation"
        component={state === null ? AuthForm : OrientationForm}
      /> */}
      {/* <Route
        exact
        path="/edit-orientation/:id"
        component={state === null ? AuthForm : EditOrientationComponent}
      /> */}
      {/* <Route
        exact
        path="/edit-question/:id/:title/:type"
        component={state === null ? AuthForm : EditQuestionsComponent}
      /> */}
      {/* <Route
        exact
        path="/orientation-view"
        component={state === null ? AuthForm : OrientationViewPage}
      /> */}
      {/* <Route
        exact
        path="/trainings"
        component={state === null ? AuthForm : TrainingsPage}
      /> */}
      {/* <Route
        exact
        path="/view-trainings/:id"
        component={state === null ? AuthForm : TrainingsComponent}
      /> */}
      {/* <Route
        exact
        path="/create-training/:id"
        component={state === null ? AuthForm : TrainingForm}
      /> */}
      {/* <Route
        exact
        path="/edit-training/:id"
        component={state === null ? AuthForm : EditTrainingForm}
      /> */}
      {/* <Route
        exact
        path="/issue-letters"
        component={state === null ? AuthForm : IssueLettersPage}
      /> */}
      {/* <Route
        exact
        path="/view-issue-letters/:id"
        component={state === null ? AuthForm : IssueLetterComponent}
      /> */}
      {/* <Route
        exact
        path="/create-issue-letter/:id"
        component={state === null ? AuthForm : IssueLetterForm}
      /> */}
      {/* <Route
        exact
        path="/edit-issue-letter/:rowId"
        component={state === null ? AuthForm : EditIssueLetterForm}
      /> */}
      {/* <Route
        exact
        path="/company-policies"
        component={state === null ? AuthForm : CompanyPoliciesPage}
      /> */}
      {/* <Route
        exact
        path="/create-company-policy"
        component={state === null ? AuthForm : CompanyPolicyForm}
      /> */}
      {/* <Route
        exact
        path="/edit-company-policy/:rowId"
        component={state === null ? AuthForm : EditCompanyPolicyForm}
      /> */}
      {/* <Route
        exact
        path="/compensation-benefits"
        component={state === null ? AuthForm : CompensationBenefitsPage}
      /> */}
      {/* <Route
        exact
        path="/view-compensations/:id"
        component={state === null ? AuthForm : CompensationBenifitsComponent}
      /> */}
      {/* <Route
        exact
        path="/create-compensation/:id"
        component={state === null ? AuthForm : CreateCompensation}
      /> */}
      {/* <Route
        exact
        path="/edit-compensation/:rowId"
        component={state === null ? AuthForm : EditCompensation}
      /> */}
      {/* <Route
        exact
        path="/users"
        component={state === null ? AuthForm : UsersPage}
      /> */}
      {/* <Route
        exact
        path="/view-user/:uid"
        component={state === null ? AuthForm : IndividualUserDisplay}
      /> */}
      {/* <Route
        exact
        path="/create-user"
        component={state === null ? AuthForm : UserForm}
      /> */}
      {/* <Route
        exact
        path="/edit-user/:id"
        component={state === null ? AuthForm : EditUserForm}
      /> */}
      {/* <Route exact path="/edit-question/:id" component={state === null ? AuthForm : EditQuestionsComponent} /> */}
      {/* <Route
        exact
        path="/add-questions/:id/:title/:type"
        component={state === null ? AuthForm : AddQuestionsComponent}
      /> */}
      {/* <Route exact path="/add-training-question/:id" component={state === null ? AuthForm : AddQuestionsComponent} /> */}
      {/* <Route
        exact
        path="/create-workflow"
        component={state === null ? AuthForm : CreateWorkFlowPage}
      /> */}
      {/* <Route
        exact
        path="/workflow/:wid"
        component={state === null ? AuthForm : WorkFlowPage}
      /> */}
      {/* <Route
        exact
        path="/workflow-edit/:wid"
        component={state === null ? AuthForm : WorkFlowEditPage}
      /> */}
      {/* <Route
        exact
        path="/edit-workflow/:wid"
        component={state === null ? AuthForm : EditWorkFlowPage}
      /> */}
      {/* <Route
        exact
        path="/edit-workflow-hiring/:wid"
        component={state === null ? AuthForm : EditHiringWorkFlowPage}
      /> */}
      {/* <Route
        exact
        path="/reports"
        component={state === null ? AuthForm : ReportsPage}
      /> */}
      {/* <Route
        exact
        path="/paper-book"
        component={state === null ? AuthForm : PaperbookPage}
      /> */}
      {/* <Route
        exact
        path="/offboarding"
        component={state === null ? AuthForm : OffboardingPage}
      /> */}

      {/* <Route
        exact
        path="/individual-recruit"
        component={state === null ? AuthForm : HiringCandidateDetailsView}
      /> */}
      {/* <Route
        exact
        path="/hiring-workflow/:wid"
        component={state === null ? AuthForm : HiringWorkFlowPage}
      /> */}
      {/* <Route
        exact
        path="/hiring-flow-candidate/:wid"
        component={state === null ? AuthForm : HiringFlowForm}
      /> */}
      {/* <Route
        exact
        path="/public-link/:wid"
        component={state === null ? AuthForm : PublicLink}
      /> */}
      {/* <Route
        exact
        path="/edit-hiring-workflow/:wid"
        component={state === null ? AuthForm : HiringWorkFlowEditPage}
      /> */}
      {/* <Route
        exact
        path="/hiring"
        component={state === null ? AuthForm : HiringForm}
      /> */}
      {/* <Route
        exact
        path="/screeners"
        component={state === null ? AuthForm : ScreenersPage}
      /> */}
      {/* <Route
        exact
        path="/edit-screener/:id"
        component={state === null ? AuthForm : ScreenerQuestionsViewComponent}
      /> */}
      {/* <Route
        exact
        path="/add-screener-question/:id"
        component={state === null ? AuthForm : AddScreenerQuestions}
      /> */}
      {/* <Route
        exact
        path="/edit-screener-question/:id/:qid"
        component={state === null ? AuthForm : EditScreenerQuestion}
      /> */}
      {/* <Route
        exact
        path="/tests"
        component={state === null ? AuthForm : TestsPage}
      /> */}
      {/* <Route
        exact
        path="/edit-test/:id"
        component={state === null ? AuthForm : TestQuestionsViewComponent}
      /> */}
      {/* <Route
        exact
        path="/add-test-question/:id"
        component={state === null ? AuthForm : AddTestQuestions}
      /> */}
      {/* <Route
        exact
        path="/edit-test-question/:id/:qid"
        component={state === null ? AuthForm : EditTestQuestion}
      /> */}
    </Fragment>
  );
};

export default Routers;
