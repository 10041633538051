/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";

// Soft UI Dashboard React example components
// import DefaultNavbar from "components/toolkit/Navbars/DefaultNavbar";
import PageLayout from "components/toolkit/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Custom styles for the Baise
import styles from "layouts/authentication/components/CoverLayout/styles";

// Soft UI Dashboard React page layout routes
// import routes from "routes";

import Logo from "../../../../assets/images/LogoDark.png";
function CoverLayout({
    routes,
    color,
    header,
    title,
    description,
    image,
    logoImage,
    top,
    children,
    fontSize,
    gridWidth = 3,
}) {
    const classes = styles({ image });
    // const company = useSelector((state) => state.company.basic_details);

    return (
        <PageLayout background='white'>
            {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/soft-ui-dashboard-material-ui",
          label: "free download",
          color: "dark",
        }}
      /> */}

            <Grid
                container
                justifyContent='center'
                className={classes.coverLayout}
                style={{ marginTop: "8%" }}>
                <Grid item xs={11} sm={8} md={5} xl={gridWidth}>
                    {/* <Grid item xs={11} sm={8} md={5} xl={3}> */}
                    <SuiBox>
                        <SuiBox pt={3} px={3}>
                            <div style={{ textAlign: "center" }}>
                                {logoImage ? (
                                    <img
                                        width='100px'
                                        height='100px'
                                        src={logoImage ?? Logo}
                                        alt=''
                                    />
                                ) : (
                                    <img src={Logo} alt='' />
                                )}
                            </div>
                            {!header ? (
                                <>
                                    {title && (
                                        <SuiBox mb={1}>
                                            <SuiTypography
                                                variant='h3'
                                                fontWeight='bold'
                                                textColor={color}
                                                textGradient>
                                                {title}
                                            </SuiTypography>
                                        </SuiBox>
                                    )}
                                    <SuiBox
                                        mt={6}
                                        display='flex'
                                        flexDirection='row'
                                        justifyContent='center'>
                                        <SuiTypography
                                            variant={"subtitle2"}
                                            fontWeight='regular'
                                            textColor='text'>
                                            {description}
                                        </SuiTypography>
                                    </SuiBox>
                                </>
                            ) : (
                                header
                            )}
                        </SuiBox>
                        <SuiBox p={3}>{children}</SuiBox>
                    </SuiBox>
                    <Footer />
                </Grid>
                {/* <Grid item xs={12} md={5}>
          <SuiBox
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            customClass={classes.coverLayout_imageBox}
          >
            <SuiBox customClass={classes.coverLayout_image} />
          </SuiBox>
        </Grid> */}
            </Grid>
        </PageLayout>
    );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
    header: "",
    title: "",
    description: "",
    color: "info",
    top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    header: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    top: PropTypes.number,
    children: PropTypes.node.isRequired,
};

export default CoverLayout;
