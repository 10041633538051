import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = ({ ...props }) => {
  const { size, className } = props;
  return (
    <CircularProgress className={className} size={size} color="secondary" />
  );
};

export default Spinner;
