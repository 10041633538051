import EmailLoginComponent from "components/required-components/emailLogin-component/EmailLoginComponent";
import PublicLinkComponent from "components/required-components/publicLink-component/PublicLinkComponent";
import React from "react";

// import AuthForm from "layouts/authentication/sign-in";
const AuthForm = React.lazy(() => import("layouts/authentication/sign-in"));
const ForgotPassword = React.lazy(() =>
  import("components/required-components/forgot-password/ForgotPassword")
);
const ForgotPasswordLink = React.lazy(() =>
  import("components/required-components/forgot-password/ForgotPasswordLink")
);
const ResetPassword = React.lazy(() =>
  import("components/required-components/reset-password/ResetPassword")
);
const TeamInvitePage = React.lazy(() =>
  import("components/required-components/team-component/TeamInvitePage")
);

const AccessCandidateTymelineForm = React.lazy(() =>
  import("pages/Tymeline/AccessCandidateTymelineForm")
);
const MasterLoginPage = React.lazy(() => import("pages/Tymeline/master"));
const selfVerificationPage = React.lazy(() =>
  import("pages/Tymeline/self-verification")
);
export const LOGIN = "login";
export const FORGOT_PASSWORD = "forgot-password";
export const FORGOT_PASSWORD_VERIFY = "password-verify";
export const RESET_PASSWORD = "/reset-password/:token";
export const ACCEPT_TEAM_INVITE = "/acceptteaminvite/:token";
export const PUBLIC_LINK = "public-link";
export const GENERATE_MASTER_KEY = "generate-master-key";
export const TYMELINE_LOGIN = "tymeline-login";

const routes = [
  {
    // name: "",
    path: "/login",
    component: EmailLoginComponent,
    name: LOGIN,
    exact: true,
  },
  {
    // name: "",
    path: "/",
    component: EmailLoginComponent,
    name: LOGIN,
    exact: true,
  },
  {
    name: FORGOT_PASSWORD,
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
  {
    name: FORGOT_PASSWORD_VERIFY,
    path: "/password-verify",
    component: ForgotPasswordLink,
    exact: true,
  },
  {
    name: RESET_PASSWORD,
    path: "/reset-password/:token",
    component: ResetPassword,
    exact: true,
  },
  {
    name: ACCEPT_TEAM_INVITE,
    path: "/acceptteaminvite/:token",
    component: TeamInvitePage,
    exact: true,
  },
  {
    name: PUBLIC_LINK,
    path: "/public-profile-link/:profileId",
    component: PublicLinkComponent,
    exact: true,
  },
  {
    path: "/master/69c68c7c417fa04780408fe07fdad319",
    type: "collapse",
    name: "Master",
    component: MasterLoginPage,
    exact: true,
    noSuperAdmin: false,
  },
  {
    path: "/self-initiate/:token/:requestId",
    type: "collapse",
    name: "slef-initiate",
    component: selfVerificationPage,
    exact: true,
    noSuperAdmin: false,
  },
  {
    name: TYMELINE_LOGIN,
    path: "/tymeline-login",
    component: AuthForm,
    exact: true,
  },
  // Route for Invite team to view tymeline records
  {
    path: "/tymeline/access-candidate-tymeline/:cn/:email/:token",
    type: "collpase",
    component: AccessCandidateTymelineForm,
    exact: true,
  },
];

export const getUnauthConfig = (routeName) => {
  return routes.find(({ name }) => name === routeName);
};

export default routes;
