/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import {
    useLocation,
    //  Link,
    useHistory,
} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";
// import SuiInput from "components/toolkit/SuiInput";

// Soft UI Dashboard React example components
import Breadcrumbs from "components/toolkit/Breadcrumbs";
import NotificationItem from "components/toolkit/NotificationItem";

// Custom styles for DashboardNavbar
import styles from "components/toolkit/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { MenuItem, Box } from "components";
// import { ListItemIcon } from "components";
// import { useNavConfig } from "./useNavConfig";
// import { styled } from "@mui/styles";
// import { ExpandMore } from "components/toolkit";
// import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from "@mui/material/AccordionSummary";
// import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Fade } from "@mui/material";
import { setCurrentUser } from "redux/user/UserActions";
import ModalLayout from "components/layouts/ModalLayout";
import { useSelector, useDispatch } from "react-redux";
import { getAccountDetails } from "redux/db-user/DBuserActions";
import { Avatar } from "@mui/material";
import { selectProfile } from "selectors/profile/profileSelector";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
// import { styled, alpha } from "@mui/material/styles";
import { clearUsers } from "redux/db-user/DBuserActions";
import { setCompanyDetails } from "redux/company-details/CompanyDetailActions";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { setDashboardData } from "redux/userdashboard/UserDashboardAction";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

function DashboardNavbar({ absolute, light, isMini }) {
    const [navbarType, setNavbarType] = useState();
    const sentinelRef = useRef(null);
    // const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const state = useSelector((state) => state.user.currentUser);
    const account = useSelector((state) => state.dbUser.account_details);
    const profile = useSelector(selectProfile(account?.id));
    const [controller, dispatch] = useSoftUIController();
    const logoutDispatch = useDispatch();
    const accountDetails = useSelector((state) => state.dbUser.account_details);
    const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    // const open = Boolean(anchorEl);
    const classes = styles({ transparentNavbar, absolute, light, isMini });
    const route = useLocation().pathname.split("/").slice(1);
    // const navConfig = useNavConfig();
    const history = useHistory();
    const [logout, setLogout] = useState(null);
    const openLogout = Boolean(logout);
    const handleLogout = (event) => {
        setLogout(event.currentTarget);
    };
    const handleCloseLogout = () => {
        setLogout(null);
    };

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }
        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar(event) {
            console.log("handleTransparentNavbar", event);
            const isTransparent =
                (fixedNavbar && window.scrollY === 0) || !fixedNavbar;
            dispatch({
                type: "TRANSPARENT_NAVBAR",
                value: isTransparent,
            });
        }

        window.addEventListener("scroll", handleTransparentNavbar);
        logoutDispatch(getAccountDetails(state.account_id));

        // // Remove event listener on cleanup
        return () => {
            window.removeEventListener("scroll", handleTransparentNavbar);
        };
    }, [dispatch, fixedNavbar, logoutDispatch, state?.account_id]);

    const handleMiniSidenav = () =>
        dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
    // const handleConfiguratorOpen = () =>
    //   dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });

    // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //   setAnchorEl(null);
    // };
    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            style={{ marginTop: "1rem" }}>
            <NotificationItem
                image={<img src={team2} alt='person' />}
                title={["New message", "from Laur"]}
                date='13 minutes ago'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt='person' />}
                title={["New album", "by Travis Scott"]}
                date='1 day'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color='secondary'
                image={
                    <Icon
                        fontSize='small'
                        className='material-icon-round text-white'>
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date='2 days'
                onClick={handleCloseMenu}
            />
        </Menu>
    );

    // const Accordion = styled((props) => (
    //   <MuiAccordion disableGutters elevation={0} square {...props} />
    // ))(({ theme }) => ({
    //   border: `none`,
    //   "&:not(:last-child)": {
    //     borderBottom: 0,
    //   },
    //   minHeight: "10px",
    //   backgroundColor: "none",
    //   "&:before": {
    //     display: "none",
    //   },
    // }));

    // const AccordionSummary = styled((props) => (
    //   <MuiAccordionSummary
    //     expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />}
    //     {...props}
    //   />
    // ))(({ theme }) => ({
    //   backgroundColor: "#fff",
    //   padding: 0,
    //   minHeight: 0,
    //   "& .MuiAccordionSummary-content": {
    //     marginLeft: 0,
    //     fontWeight: 400,
    //     margin: 0,
    //     fontSize: "0.875rem",
    //     color: "#67748e",
    //   },
    // }));

    // const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //   padding: "1px 0.8rem 0",
    //   borderTop: "none",
    // }));
    // const [expanded, setExpanded] = useState("");

    // const handleChange = (panel) => (event, newExpanded) => {
    //   setExpanded(newExpanded ? panel : false);
    // };

    const handleLogoutOnline = () => {
        logoutDispatch(setCurrentUser(null));
        logoutDispatch(clearUsers(null));
        logoutDispatch(setCompanyDetails(null));
        logoutDispatch(setDashboardData(null));

        localStorage.clear();
        history.push("/login");
    };

    const handleLogoutClick = () => {
        setShowModal(true);
        handleCloseLogout();
    };
    const toggle = () => {
        setShowModal(false);
    };

    const handleProfileClick = () => {
        handleCloseLogout();
        history.push("/profilePage");
    };

    const checkISAlphaNumericString = () => {
        const regEx = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
        const isStringAlphaNumeric = regEx.test(route[route.length - 1]);
        return isStringAlphaNumeric ? "" : route[route.length - 1];
    };

    return (
        <AppBar
            ref={sentinelRef}
            position={absolute ? "absolute" : navbarType}
            color='inherit'
            sx={{
                // added filter in sx since in mui  v5 there is a bug in makeStyles
                backdropFilter: ({ transparentNavbar, absolute }) =>
                    transparentNavbar || absolute
                        ? "none"
                        : `saturate(200%) blur(30px)`,
            }}
            style={{ marginBottom: "0.5rem" }}
            className={classes.navbar}>
            <ModalLayout
                showModal={showModal}
                handleClick={handleLogoutOnline}
                toggle={toggle}
            />
            <Toolbar className={classes.navbar_container}>
                <SuiBox
                    customClass={classes.navbar_row}
                    color='inherit'
                    mb={{ xs: 1, md: 0 }}>
                    <IconButton
                        size='small'
                        color='inherit'
                        className={classes.navbar_mobile_menu}
                        onClick={handleMiniSidenav}>
                        {/* <Icon>{miniSidenav ? "menu" : "menu"}</Icon> */}
                        <Icon>{"menu"}</Icon>
                    </IconButton>

                    {/* Added this condidtion to display breadcrumbs conditionally. 
          Reason is that we need to hide breadcrumbs on individual user page */}
                    {!route.includes("view-user") && (
                        <Breadcrumbs
                            icon='home'
                            title={checkISAlphaNumericString()}
                            route={route}
                            light={light}
                        />
                    )}
                </SuiBox>
                {isMini ? null : (
                    <SuiBox customClass={classes.navbar_row_bottom}>
                        <SuiBox pr={1}>
                            {/* <SuiInput
                placeholder="Type here..."
                withIcon={{ icon: "search", direction: "left" }}
                customClass={classes.navbar_input}
              /> */}
                            {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon size="md" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
                        </SuiBox>
                        <SuiBox
                            color={light ? "white" : "inherit"}
                            customClass={classes.navbar_section_desktop}>
                            {/* <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "widgets" : "menu"}</Icon>
              </IconButton> */}
                            {/* <IconButton
                color="inherit"
                className={classes.navbar_icon_button}
                onClick={handleClick}
                // onClick={handleConfiguratorOpen}
              >
                <Icon>menu_open</Icon>
              </IconButton> */}
                            {/* <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 3,
                    "& .MuiAvatar-root": {
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 9,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              > */}
                            {/* {navConfig.map((item, i) =>
                  !item.subMenu ? (
                    <Link to={item.to} key={i}>
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          {item.Icon}
                          
                        </ListItemIcon>
                        {item.name}
                      </MenuItem>
                    </Link>
                  ) : (
                    <div className="submenu-parent" key={i}>
                      <ListItemIcon className="submenu-icon">
                        {item.Icon}
                       
                      </ListItemIcon>
                      <div className="submenu-section">
                        <ListItemIcon
                          style={{
                            opacity: "0",
                            minWidth: "36px",
                          }}
                        >
                          {item.Icon}
                         
                        </ListItemIcon>
                        <Accordion
                          expanded={expanded === `${item.name}`}
                          onChange={handleChange(item.name)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            {item.name}
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ padding: 0 }}
                            onClick={handleClose}
                          >
                            {item.list?.map((submenu, index) => (
                              <Link
                                to={
                                  !submenu.create
                                    ? `${item.to}${submenu.sub_menu_id}`
                                    : `${submenu.to}`
                                }
                                key={i + index}
                              >
                                <MenuItem
                                  style={{
                                    paddingLeft: "0.7rem",
                                  }}
                                >
                                  {submenu.title}
                                </MenuItem>
                              </Link>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  )
                )} */}
                            {/* </Menu> */}

                            {/* <IconButton
                color="inherit"
                className={classes.navbar_icon_button}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>notifications</Icon>
              </IconButton> */}
                            {renderMenu()}
                        </SuiBox>
                        {route.includes("user-dashboard") && (
                            <Tooltip title='The information presented on this page will be exclusively visible to you.'>
                                <Box className='cursor-pointer'>
                                    <InfoIcon />
                                </Box>
                            </Tooltip>
                        )}

                        <IconButton
                            className={classes.navbar_icon_button}
                            aria-controls='fade-menu'
                            aria-haspopup='true'
                            aria-expanded={openLogout ? "true" : undefined}
                            onClick={handleLogout}>
                            {/* <Icon className={light ? "text-white" : "text-dark"}>
                account_circle
              </Icon> */}
                            <Box display='flex' alignItems='center' gap={1}>
                                <Avatar
                                    sx={{ width: 36, height: 36 }}
                                    src={accountDetails?.profilePhoto?.url}>
                                    {profile?.firstName.substr(0, 1)}
                                </Avatar>

                                <SuiTypography
                                    variant='button'
                                    fontWeight='medium'
                                    textColor={light ? "white" : "dark"}>
                                    {accountDetails?.firstName +
                                        " " +
                                        accountDetails?.lastName ?? ""}
                                </SuiTypography>
                            </Box>
                        </IconButton>
                        <Menu
                            id='fade-menu'
                            MenuListProps={{
                                "aria-labelledby": "fade-button",
                            }}
                            anchorEl={logout}
                            open={openLogout}
                            onClose={handleCloseLogout}
                            TransitionComponent={Fade}>
                            <MenuItem onClick={handleProfileClick}>
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogoutClick}>
                                Logout
                            </MenuItem>
                        </Menu>
                        <IconButton />
                    </SuiBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
