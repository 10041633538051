import { Button } from "components/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import { userSelector } from "selectors/user";
import { MdAdd } from "react-icons/md";

// This component was created for white label product to pick up the primary color.
// We no longer need this component in Tymeline platform
// Re-taining only for spinner. It is basically DEPRECATED

const ButtonComponent = ({
    label,
    onClick,
    type,
    width,
    disable,
    mrgt,
    showSpinner,
    pdng,
    size,
    variant,
    showCreateIcon,
    className = null,
}) => {
    const state = useSelector(userSelector);
    const color = state?.primary ?? "#38B6FF";
    // state !== null
    //   ? state.primary !== undefined
    //     ? state.primary
    //     : "#38B6FF"
    //   : "#38B6FF";
    return (
        <Button
            className={`border-0 btn btn-block text-light ${className}`}
            customClass={className ?? ""}
            variant={"gradient"}
            size={size}
            style={{
                backgroundColor: color,
                width: width,
                marginRight: mrgt,
                padding: pdng,
            }}
            buttonColor='secondary'
            onClick={onClick}
            type={type}
            disabled={disable}>
            {showCreateIcon && (
                <MdAdd
                    style={{
                        color: "#FFF",
                        marginRight: "0.3rem",
                    }}
                    size={20}
                />
            )}
            {label}{" "}
            {showSpinner && (
                <i
                    className='fa fa-circle-o-notch fa-spin'
                    style={{ marginLeft: "0.5rem", fontSize: "1rem" }}></i>
            )}
        </Button>
    );
};

export default ButtonComponent;
