export const Labels = {
  login: {
    user: {
      name: "email",
      placeholder: "Email",
      label: "Email",
      type: "email",
    },
    password: {
      name: "password",
      placeholder: "Password",
      label: "Password",
      type: "password",
    },
  },
  orientation: {
    title: {
      name: "title",
      placeholder: "Enter orientation title",
      label: "Orientation Title *",
      type: "text",
    },
  },
  general: {
    title: {
      name: "title",
      placeholder: "Title",
      label: "Title",
      type: "text",
    },
    role: {
      name: "role_title",
      placeholder: "Enter role",
      label: "Role",
      type: "text",
    },
    description: {
      name: "description",
      placeholder: "enter job description",
      label: "Job description",
      type: "textarea",
    },
    minExperience: {
      name: "minimum_years_of_experience",
      placeholder: "Minimum Years of experience",
      label: "Min Experience",
      type: "number",
    },
    maxExperience: {
      name: "maximum_years_of_experience",
      placeholder: "Maximum Years of experience",
      label: "Max Experience",
      type: "number",
    },
    skills: {
      name: "skills",
      placeholder: "select skills",
      label: "Skills",
      type: "select",
    },
    details: {
      name: "onboarding_details",
      placeholder: "Onboarding details",
      label: "Onboarding details *",
      type: "textarea",
    },
    duration: {
      name: "duration",
      placeholder: "enter duration per each question",
      label: "Duration per question",
      type: "text",
    },
    embed: {
      name: "embed_link",
      placeholder: "enter embed video url",
      label: "Embed",
      type: "textarea",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    question: {
      name: "question",
      placeholder: "enter question",
      label: "Question",
      type: "text",
    },
    answer: {
      label: "Choices",
    },
    radio1: {
      name: "radio1",
      type: "radio",
    },
    radio: {
      name: "answer",
      type: "radio",
    },
    option1: {
      name: "choice1",
      placeholder: "option1",
      type: "text",
    },
    option2: {
      name: "choice2",
      placeholder: "option2",
      type: "text",
    },
    option3: {
      name: "choice3",
      placeholder: "option3",
      type: "text",
    },
    option4: {
      name: "choice4",
      placeholder: "option4",
      type: "text",
    },
  },
  reports: {
    from: {
      name: "from_date",
      placeholder: "from date",
      label: "From date",
      type: "date",
    },
    to: {
      name: "to_date",
      placeholder: "to date",
      label: "To date",
      type: "date",
    },
  },
  training: {
    submenu: {
      name: "title",
      placeholder: "Submenu Title",
      label: "Submenu title",
      type: "text",
    },
    title: {
      name: "title",
      placeholder: "Training Title",
      label: "Title *",
      type: "text",
    },
    role: {
      name: "role_title",
      placeholder: "Enter role",
      label: "Role",
      type: "text",
    },
    duration: {
      name: "question_duration",
      placeholder: "per question in second",
      label: "Duration per question (in seconds only) *",
      type: "number",
    },
    trainingTextDescription: {
      name: "training_description",
      placeholder: "Description to show for the Candidate",
      label: "Description",
      type: "textarea",
    },
    youtube: {
      name: "youtube_link",
      placeholder: "Enter youtube link",
      label: "youtube",
      type: "textarea",
    },
    website: {
      name: "link_to_website",
      placeholder: "Link to external website",
      label: "Link To External Website",
      type: "text",
    },
    embed: {
      name: "embed_link",
      placeholder: "enter embed video url",
      label: "Embed / Youtube (embed)",
      type: "textarea",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    question: {
      name: "question",
      placeholder: "enter question",
      label: "Question",
      type: "text",
    },
    answer: {
      label: "Choices",
    },
    radio1: {
      name: "radio1",
      type: "radio",
    },
    radio: {
      name: "answer",
      type: "radio",
    },
    option1: {
      name: "choice1",
      placeholder: "option1",
      type: "text",
    },
    option2: {
      name: "choice2",
      placeholder: "option2",
      type: "text",
    },
    option3: {
      name: "choice3",
      placeholder: "option3",
      type: "text",
    },
    option4: {
      name: "choice4",
      placeholder: "option4",
      type: "text",
    },
  },
  learning: {
    title: {
      name: "title",
      placeholder: "Learning Title",
      label: "Title *",
      type: "text",
    },
    duration: {
      name: "durationPerQuestion",
      placeholder: "per question in second",
      label: "Duration per question (in seconds only) *",
      type: "number",
    },
    overallDuration: {
      name: "overallDuration",
      placeholder: "overallDuration of course",
      label: "Duration of course (in days only) *",
      type: "number",
    },
    skillsCovered: {
      name: "skillsCovered",
      placeholder: "Skills Covered",
      label: "Skills Covered",
      type: "dropdown",
    },
    description: {
      name: "description",
      placeholder: "Description to show for the Candidate",
      label: "Description",
      type: "textarea",
    },
    youtube: {
      name: "youtube_link",
      placeholder: "Enter youtube link",
      label: "youtube",
      type: "textarea",
    },
    website: {
      name: "link_to_website",
      placeholder: "Link to external website",
      label: "Link To External Website",
      type: "text",
    },
    embed: {
      name: "embed_link",
      placeholder: "enter embed video url",
      label: "Embed / Youtube (embed)",
      type: "textarea",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    question: {
      name: "question",
      placeholder: "enter question",
      label: "Question",
      type: "text",
    },
    answer: {
      label: "Choices",
    },
    radio1: {
      name: "radio1",
      type: "radio",
    },
    radio: {
      name: "answer",
      type: "radio",
    },
    option1: {
      name: "choice1",
      placeholder: "option1",
      type: "text",
    },
    option2: {
      name: "choice2",
      placeholder: "option2",
      type: "text",
    },
    option3: {
      name: "choice3",
      placeholder: "option3",
      type: "text",
    },
    option4: {
      name: "choice4",
      placeholder: "option4",
      type: "text",
    },
  },
  company: {
    corporate: {
      name: "company_name",
      placeholder: "Company name",
      label: "Company name *",
      type: "text",
    },
    displayName: {
      name: "company_display_name",
      placeholder: "Display name",
      label: "Display name *",
      type: "text",
    },
    address: {
      name: "company_address",
      placeholder: "Company address",
      label: "Address *",
      type: "text",
    },
    website: {
      name: "website",
      placeholder: "Website",
      label: "Website Url *",
      type: "url",
    },
    taxId: {
      name: "tax",
      placeholder: "Tax Id",
      label: "Tax Id",
      type: "text",
    },
    cin: {
      name: "cin",
      placeholder: "CIN",
      label: "CIN *",
      type: "text",
    },
    strength: {
      name: "company_strength",
      placeholder: "Company strength",
      label: "Company strength *",
      type: "number",
    },
    primary: {
      name: "theme_primary_colour",
      placeholder: "Enter primary color",
      label: "Primary color",
      type: "text",
    },
    primary2: {
      name: "theme_primary_colour_two",
      placeholder: "Enter primary color two",
      label: "Primary color Two",
      type: "text",
    },
    secondary: {
      name: "theme_secondary_colour",
      placeholder: "Enter secondary color",
      label: "Secondary color",
      type: "text",
    },
    secondary2: {
      name: "theme_secondary_colour_two",
      placeholder: "Enter secondary color",
      label: "Secondary color Two",
      type: "text",
    },
    font: {
      name: "fonts",
      placeholder: "Select font",
      label: "Font",
      type: "select",
    },
    employee_id: {
      name: "employeeId_prefix",
      placeholder: "Enter employee id prefix",
      label: "Employee id prefix",
      type: "text",
    },
    file: {
      name: "logo",
      placeholder: "Select Logo",
      label: "Upload Logo",
      type: "file",
    },
  },
  user: {
    firstName: {
      name: "firstName",
      placeholder: "First name",
      label: "First name",
      type: "text",
    },
    lastName: {
      name: "lastName",
      placeholder: "Last name",
      label: "Last name",
      type: "text",
    },
    email: {
      name: "email",
      placeholder: "Email",
      label: "Email",
      type: "email",
    },
    role: {
      name: "role",
      placeholder: "Select role",
      label: "Role",
      type: "select",
    },
    department: {
      name: "department",
      placeholder: "Select Department",
      label: "Department",
      type: "select",
    },
    teams: {
      name: "team",
      placeholder: "Select Team",
      label: "Team",
      type: "select",
    },
    select_all: {
      name: "select_all",
      placeholder: "Select All",
      label: "Select All",
      type: "checkbox",
    },
    hiring: {
      name: "hiring",
      placeholder: "Hiring & Onboarding",
      label: "Hiring & Onboarding",
      type: "checkbox",
    },
    learning: {
      name: "learning",
      placeholder: "Learning Platform",
      label: "Learning Platform",
      type: "checkbox",
    },
  },
  onboard: {
    education: {
      type: "checkbox",
      label: "Education Details",
    },
    work: {
      type: "checkbox",
      label: "Work Details",
    },
    personal: {
      type: "checkbox",
      label: "Personal Details",
    },
    role: {
      name: "role",
      placeholder: "Select role",
      label: "Role *",
      type: "select",
    },
    phone: {
      name: "phone",
      placeholder: "Contact Number",
      label: "Contact Number *",
      type: "number",
    },
    name: {
      name: "name",
      placeholder: "Name",
      label: "Name *",
      type: "text",
    },
    subject: {
      name: "subject",
      placeholder: "Subject",
      label: "Subject *",
      type: "text",
    },
    contact: {
      name: "contact",
      placeholder: "Contact Number",
      label: "Contact Number *",
      type: "text",
    },
    email: {
      type: "email",
      label: "Email *",
      name: "email",
      placeholder: "your@email.com",
    },
    expiryDays: {
      name: "token_duration",
      placeholder: "Select days",
      label: "Link expires in *",
      type: "number",
    },
    reportee: {
      name: "reportees",
      placeholder: "Select reportee",
      label: "Reportee",
      type: "select",
    },
    compensation: {
      name: "compensation",
      placeholder: "Compensation",
      label: "Compensation *",
      type: "text",
    },
    workflow: {
      name: "workflow_id",
      placeholder: "Workflow",
      label: "Workflow *",
      type: "select",
    },
  },
  forgot: {
    email: {
      type: "email",
      label: "Email",
      name: "email",
      placeholder: "your@email.com",
    },
    password: {
      type: "password",
      label: "Password",
      name: "password",
      placeholder: "enter password",
    },
    confirm: {
      type: "password",
      label: "Confirm password",
      name: "confirm_password",
      placeholder: "confirm password",
    },
  },
  personal: {
    firstName: {
      label: "First name *",
      name: "first_name",
      placeholder: "First name",
      type: "text",
    },
    lastName: {
      label: "Last name *",
      name: "last_name",
      placeholder: "Last name",
      type: "text",
    },
    middleName: {
      label: "Middle name",
      name: "middle_name",
      placeholder: "Middle name",
      type: "text",
    },
    email: {
      label: "Email *",
      name: "email",
      placeholder: "Email",
      type: "email",
    },
    phone: {
      label: "Phone *",
      name: "phone",
      placeholder: "Phone",
      type: "text",
    },
    dob: {
      label: "Date of birth",
      name: "date_of_birth",
      placeholder: "date of birth",
      type: "date",
    },
    alternate: {
      label: "Alternate phone number *",
      name: "alternate_phone_number",
      placeholder: "Alternate phone number",
      type: "text",
    },
    address: {
      label: "Address *",
      name: "address",
      placeholder: "Address",
      type: "text",
    },
    city: {
      label: "City *",
      name: "city",
      placeholder: "City",
      type: "text",
    },
    state: {
      label: "State *",
      name: "state",
      placeholder: "State",
      type: "text",
    },
    country: {
      label: "Country *",
      name: "country",
      placeholder: "Country",
      type: "text",
    },
    pincode: {
      label: "Pincode *",
      name: "pincode",
      placeholder: "Pincode",
      type: "text",
    },
    aadhar: {
      label: "Aadhar number *",
      name: "aadhar_number",
      placeholder: "Aadhar number",
      type: "text",
    },
    aadhar_front_photo: {
      label: "Upload Aadhar front *",
      name: "aadhar_front_photo",
      type: "file",
    },
    aadhar_back_photo: {
      label: "Upload Aadhar back *",
      name: "aadhar_back_photo",
      type: "file",
    },
    pan_number: {
      label: "Pan number",
      name: "pan_number",
      placeholder: "Pan number",
      type: "text",
    },
    pan_photo: {
      label: "Upload pan",
      name: "pan_photo",
      type: "file",
    },
    passport_number: {
      label: "Passport number",
      name: "passport_number",
      placeholder: "Passport number",
      type: "text",
    },
    passport_front_photo: {
      label: "Upload passport front",
      name: "passport_front_photo",
      type: "file",
    },
    passport_back_photo: {
      label: "Upload passport back",
      name: "passport_back_photo",
      type: "file",
    },
  },
  education: {
    university: {
      label: "University / Board *",
      name: "university",
      placeholder: "University / Board",
      type: "text",
    },
    college: {
      label: "School / College *",
      name: "college",
      placeholder: "School / College",
      type: "text",
    },
    course: {
      label: "Course *",
      name: "course",
      placeholder: "Course",
      type: "text",
    },
    year: {
      label: "Pass out year *",
      name: "year",
      placeholder: "year",
      type: "text",
    },
    description: {
      label: "Description *",
      name: "description",
      placeholder:
        "Enter project details / intern details / cultural event details / others if any",
      type: "textarea",
    },
    document: {
      label: "Upload documents *",
      name: "document",
      type: "file",
    },
  },
  work: {
    company: {
      label: "Company name *",
      name: "company_name",
      placeholder: "Company name",
      type: "text",
    },
    designation: {
      label: "Designation *",
      name: "designation",
      placeholder: "Enter designation",
      type: "text",
    },
    start: {
      label: "Start date *",
      name: "start_date",
      placeholder: "start date",
      type: "date",
    },
    end: {
      label: "End date *",
      name: "end_date",
      placeholder: "end date",
      type: "date",
    },
    description: {
      label: "Description *",
      name: "description",
      placeholder: "Enter roles and responsibilities",
      type: "textarea",
    },
    document: {
      label: "Upload documents *",
      name: "document",
      type: "file",
    },
  },
  issue: {
    title: {
      name: "title",
      label: "Title *",
      placeholder: "Enter title",
      type: "text",
    },
    boarding_type: {
      name: "boarding_type",
      label: "Boarding Type *",
      placeholder: "Select boarding type",
      type: "select",
      option1: "On-boarding",
      option2: "Off-boarding",
    },
    signature: {
      name: "signature",
      label: "Signatory Name *",
      placeholder: "Signature",
      type: "text",
    },
    signature_img: {
      name: "signature_upload",
      label: "Upload signature *",
      type: "file",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    format: {
      label: "Letter Format *",
      name: "description",
    },
  },
  company_policy: {
    title: {
      name: "title",
      label: "Title *",
      placeholder: "Enter title",
      type: "text",
    },
    upload_pdf: {
      name: "pdf_upload",
      label: "Upload PDF *",
      placeholder: "upload file",
      type: "file",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    description: {
      name: "description",
      label: "Company policy format *",
      placeholder: "Enter description",
      type: "textarea",
    },
  },
  compensation: {
    submenu: {
      name: "title",
      placeholder: "Submenu Title",
      label: "Submenu title",
      type: "text",
    },
    title: {
      name: "title",
      label: "Title *",
      placeholder: "Enter title",
      type: "text",
    },
    upload_pdf: {
      name: "pdf_upload",
      label: "Upload PDF",
      placeholder: "upload file",
      type: "file",
    },
    video: {
      name: "video_link",
      placeholder: "enter video url",
      label: "Video Link",
      type: "url",
    },
    description: {
      name: "description",
      label: "Compensation & Benefit format *",
      placeholder: "Enter description",
      type: "textarea",
    },
  },
  workFlow: {
    title: {
      name: "title",
      label: "Title",
      placeholder: "Enter title",
      type: "text",
    },
    roles: {
      name: "roles",
      label: "Roles",
      placeholder: "Select Roles",
      type: "file",
    },
    workflowType: {
      name: "workflow_type",
      label: "Workflow type (on-boarding /off-boarding)",
      placeholder: "Select Workflow type",
      type: "select",
    },
    header: {
      label: "Update the role",
    },
  },
  learningWorkFlow: {
    lpName: {
      name: "lpName",
      label: "Title",
      placeholder: "Enter title",
      type: "text",
    },
    certifyingName: {
      name: "certifyingName",
      label: "Certifier Name",
      placeholder: "Enter title",
      type: "text",
    },
    roles: {
      name: "forRoles",
      label: "Roles",
      placeholder: "Select Roles",
      type: "file",
    },
    overallDuration: {
      name: "overallDuration",
      label: "Duration to complete(in days)",
      placeholder: "Duration",
      type: "number",
    },
    skillsGained: {
      name: "skillsGained",
      label: "Skills Gained",
      placeholder: "Skills",
      type: "file",
    },
    header: {
      label: "Update the role",
    },
  },
  teams: {
    title: {
      name: "title",
      placeholder: "Enter team title",
      label: "Team title",
      type: "text",
      updatePlaceholder: "update team title",
    },
    description: {
      name: "description",
      placeholder: "Enter team description",
      label: "Team Description",
      type: "text",
    },
    categories: {
      name: "categories",
      placeholder: "Enter team categories",
      label: "Team Category",
      type: "select",
    },
  },
  errorLabels: {
    roleError: "Please select the role and proceed",
    teamError: "Please select the team and proceed",
    teamDeleteError: "Oops! it looks like a user is associate with this team.",
    linkError: "Please choose any one of the Link options",
    questionsError: "Please fill all the fields corectly",
    answerError: "Please choose the answer and proceed",
    workflowError:
      "Looks like there is a link connection missing. Please check and save",
    skillsError: "Please select the role and skills to continue",
    errorMsg: "Please fill all the fields corectly",
  },
  headerItem: {
    refresh: "Refresh",
    expand: "Expand Screen",
    zoomOut: "Zoom Out the canvas",
    zoomIn: "Zoom in canvas",
    save: "Save the workflow",
    templates: "Choose the templates",
    roles: "Edit Workflow Roles",
  },
};
