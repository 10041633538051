export const INITIAL_STATE = {
  userDetails: null,
  peopleSearchDetails: null,
  users: null,
  userDetail: null,
  account_details: null,
  error: null,
  changeRoute: false,
  nominatedCandidateDetails: null,
  filteredUsersForSelect: null,
  filteredTymelineIdUsersForSelect: null,
  dashboardUserDetail: null,
};
const DBuserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };

    case "SET_FILTERED_USERS_FOR_SELECT":
      return {
        ...state,
        filteredUsersForSelect: action.payload,
      };

    case "SET_TYMELINEID_FILTERED_USERS_FOR_SELECT":
      return {
        ...state,
        filteredTymelineIdUsersForSelect: action.payload,
      };

    case "SET_PEOPLE_SEARCH_DETAILS":
      return {
        ...state,
        peopleSearchDetails: action.payload,
      };

    case "SET_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case "SET_USER_DETAIL":
      return {
        ...state,
        userDetail: action.payload,
      };

    case "SET_DASHBOARD_USER_DETAIL":
      return {
        ...state,
        dashboardUserDetail: action.payload,
      };

    case "SET_ACCOUNT_DETAILS":
      return {
        ...state,
        account_details: action.payload,
      };

    case "SET_ERROR_DETAILS":
      return {
        ...state,
        error: action.payload,
      };

    case "CHANGED_ROUTE":
      return {
        ...state,
        changeRoute: action.payload,
      };

    case "SET_PROFILE_IMAGE_URL":
      return {
        ...state,
        profile_image_url: action.payload,
      };

    case "SET_NOMINATED_CANDIDATE_DETAILS":
      return {
        ...state,
        nominatedCandidateDetails: action.payload,
      };

    case "CLEAR_USERS":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default DBuserReducer;
