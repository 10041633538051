/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import PinterestIcon from "@mui/icons-material/Pinterest";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";

function Footer() {
  return (
    <SuiBox component="footer" py={1}>
      {/* <SuiBox component="footer" py={6}> */}
      <Grid container justifyContent="center">
        {/* <Grid item xs={12} lg={8}>
          <SuiBox display="flex" justifyContent="center" mt={1} mb={3}>
            <SuiBox
              component="a"
              href="https://www.facebook.com/quanscendence"
              target="_blank"
              mr={3}
              color="secondary"
            >
              <FacebookIcon fontSize="small" />
            </SuiBox>
            <SuiBox
              component="a"
              href="https://www.instagram.com/quanscendence/"
              target="_blank"
              mr={3}
              color="secondary"
            >
              <TwitterIcon fontSize="small" />
            </SuiBox>
            <SuiBox
              component="a"
              href="https://www.instagram.com/tymeline_id/"
              target="_blank"
              mr={3}
              color="secondary"
            >
              <InstagramIcon fontSize="small" />
            </SuiBox>
            <SuiBox
              component="a"
              href="https://www.linkedin.com/company/tymeline/"
              target="_blank"
              mr={3}
              sx={{ visibility: "hidden" }}
              color="secondary"
            >
              <LinkedInIcon fontSize="small" />
            </SuiBox>
          </SuiBox>
        </Grid> */}
        <Grid item xs={12} lg={12} className="text-center mt-3">
          <SuiTypography variant="body2" textColor="secondary">
            &copy; {new Date().getFullYear()} Powered by{" "}
            <SuiTypography
              component="a"
              href="https://tymeline.app/"
              target="_blank"
              variant="body2"
              textColor="secondary"
            >
              Tymeline.app
            </SuiTypography>{" "}
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Footer;
